import { useState } from "react";
import { rem, Box, Group, Stack, Center, HoverCard } from "@mantine/core";
import classes from "./styles/CitationHoverCard.module.css";
import { Citation } from "./models/Citation";
import { CitationSourceType } from "../../shared/enums/CitationSourceType";
import { DocumentCitation } from "./components/DocumentCitation";
import { NewsCitation } from "./components/NewsCitation";
import { EstimateCitation } from "./components/EstimateCitation";

interface CitationHoverCardProps {
  index: number;
  citation: Citation;
}

export default function CitationHoverCard({
  index,
  citation,
}: CitationHoverCardProps) {
  const [isHoverCardOpen, setIsHoverCardOpen] = useState(false);
  const { sourceType, documentId, chunkId } = citation;

  function renderSource() {
    switch (sourceType) {
      case CitationSourceType.News:
        return (
          <NewsCitation isHoverCardOpen={isHoverCardOpen} newsId={documentId} />
        );
      case CitationSourceType.Filing:
      case CitationSourceType.Transcript:
        return (
          <DocumentCitation
            sourceType={sourceType}
            isHoverCardOpen={isHoverCardOpen}
            documentId={documentId}
            chunkId={chunkId}
          />
        );
      case CitationSourceType.Estimates:
        return (
          <EstimateCitation
            isHoverCardOpen={isHoverCardOpen}
            companyId={parseInt(documentId)}
            fiscalPeriod={chunkId!}
          />
        );
      default:
        return null;
    }
  }

  return (
    <HoverCard
      onOpen={() => setIsHoverCardOpen(true)}
      onClose={() => setIsHoverCardOpen(false)}
      width={rem(571)}
      closeDelay={100}
      position="right"
      radius={rem(6)}
      keepMounted
      classNames={{
        dropdown: classes["hovercard-dropdown"],
      }}
    >
      <HoverCard.Target>
        <Box className={classes["box"]} data-active={isHoverCardOpen}>
          {index + 1}
        </Box>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Group className={classes["group"]}>
          <Center className={`${classes["center"]} ${classes["text"]}`}>
            {index + 1}
          </Center>
          <Stack className={classes["stack"]}>{renderSource()}</Stack>
        </Group>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

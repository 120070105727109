import { ScrollArea, rem, Box, Text } from "@mantine/core";
import NewsLink from "./NewsLink";
import classes from "../styles/CitationHoverCard.module.css";
import { useGetNews } from "../api/useGetNews";
import CenterLoader from "../../../shared/components/loader/CenterLoader";
import { useEffect } from "react";

interface NewsCitationProps {
  isHoverCardOpen: boolean;
  newsId: string;
}

export function NewsCitation({ isHoverCardOpen, newsId }: NewsCitationProps) {
  const { news, getNews, isLoading } = useGetNews();

  useEffect(() => {
    // Fetch news only when hover card is open and news is not already fetched
    if (!isHoverCardOpen || news) return;
    getNews(newsId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHoverCardOpen, newsId]);

  return (
    <>
      {isLoading && <CenterLoader />}
      {news && (
        <>
          <Text className={`${classes["source-text"]} ${classes["text"]}`}>
            {`${news.source}`}
          </Text>
          <ScrollArea.Autosize
            mah={rem(300)}
            scrollbars="y"
            offsetScrollbars
            scrollbarSize={4}
          >
            <Text className={`${classes["citation-text"]} ${classes["text"]}`}>
              {`“${news.summary}”`}
            </Text>
          </ScrollArea.Autosize>
          <Box py={rem(4)}>
            <NewsLink title={news.title} url={news.sourceUrl} />
          </Box>
        </>
      )}
    </>
  );
}

import { rem, Box, Text } from "@mantine/core";
import { useGetConsensusEstimates } from "../../../shared/api/useGetConsensusEstimates";
import CenterLoader from "../../../shared/components/loader/CenterLoader";
import classes from "../styles/CitationHoverCard.module.css";
import CompanyLink from "./CompanyLink";
import { useEffect } from "react";
import { EstimatesTable } from "./EstimatesTable";

interface EstimateCitationProps {
  isHoverCardOpen: boolean;
  companyId: number;
  fiscalPeriod: string;
}

export function EstimateCitation({
  isHoverCardOpen,
  companyId,
  fiscalPeriod,
}: EstimateCitationProps) {
  // fiscalPeriod is in the format "Q12022" or "FY2022"
  const year = parseInt(fiscalPeriod.slice(-4));
  const period = fiscalPeriod.slice(0, 2) == "FY" ? "Annual" : "Quarterly";
  const quarter =
    period == "Quarterly" ? parseInt(fiscalPeriod.slice(1, 2)) : undefined;
  const { estimates, getEstimates, isLoading } = useGetConsensusEstimates();

  useEffect(() => {
    // Fetch estimates only when hover card is open and estimates are not already fetched
    if (!isHoverCardOpen || estimates) return;

    getEstimates(companyId, year, year, period);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHoverCardOpen, companyId, fiscalPeriod]);

  return (
    <>
      {isLoading && <CenterLoader />}
      {estimates && estimates.length === 0 && (
        <Text className={classes["text"]}>
          No consensus estimates available for this period.
        </Text>
      )}
      {estimates && estimates.length > 0 && (
        <>
          <Text className={`${classes["source-text"]} ${classes["text"]}`}>
            {`${estimates[0].companyName} consensus estimates for ${fiscalPeriod}`}
          </Text>
          <EstimatesTable
            estimates={
              period == "Annual"
                ? estimates
                : estimates.filter((e) => e.fiscalQuarter == quarter)
            }
          />
          <Box py={rem(4)}>
            <CompanyLink
              companyId={companyId}
              companyName={estimates[0].companyName}
            />
          </Box>
        </>
      )}
    </>
  );
}

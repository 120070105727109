import { ScrollArea, rem, Box, Text } from "@mantine/core";
import { parseIntOrUndefined } from "../../../shared/utils/Number";
import CompanyDocumentLink from "./CompanyDocumentLink";
import classes from "../styles/CitationHoverCard.module.css";
import { useGetSource } from "../api/useGetSource";
import CenterLoader from "../../../shared/components/loader/CenterLoader";
import { useEffect } from "react";
import { CitationSourceType } from "../../../shared/enums/CitationSourceType";

interface CitationHoverCardProps {
  sourceType: CitationSourceType;
  isHoverCardOpen: boolean;
  documentId: string;
  chunkId: string | undefined;
}

export function DocumentCitation({
  sourceType,
  isHoverCardOpen,
  documentId,
  chunkId,
}: CitationHoverCardProps) {
  const { source, getSource, isLoading } = useGetSource();

  useEffect(() => {
    // Fetch source only when hover card is open and source is not already fetched
    if (!isHoverCardOpen || source) return;
    getSource(sourceType, documentId, parseIntOrUndefined(chunkId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHoverCardOpen, sourceType, documentId, chunkId]);

  return (
    <>
      {isLoading && <CenterLoader />}
      {source && (
        <>
          <ScrollArea.Autosize
            mah={rem(300)}
            scrollbars="y"
            offsetScrollbars
            scrollbarSize={4}
          >
            <Text className={`${classes["citation-text"]} ${classes["text"]}`}>
              {`“${source.content}”`}
            </Text>
          </ScrollArea.Autosize>
          <Box py={rem(4)}>
            <CompanyDocumentLink
              document={source}
              chunkId={parseIntOrUndefined(chunkId)}
            />
          </Box>
        </>
      )}
    </>
  );
}
